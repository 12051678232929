import React from 'react'
import Navbar from '../Navbar/Navbar'
import './Blog.css'
function Blog() {
  return (
    <div>
      <div className='Blog_uper_part'>
      <Navbar/>
      <div className='welcome_part'>
        <div>Welcome to</div>
        <div>Our Blogs</div>
        <div>
          <div></div>
          <input type="text" name="" id="" />
        </div>
      </div>
      </div>
      
    </div>
  )
}

export default Blog
