import React, { useState, useEffect } from "react";
import Navbar from "../Navbar/Navbar";
import "./Home.css";
import email from "../../Assest/email.svg";
import couple from "../../Assest/couple.svg";
import Shia_meet from "../Shia_meet/Shia_meet";
import PhoneInput from "react-phone-number-input";
import Home_main_svg from "../../Assest/home_ main_svg.svg";
import { Phone } from "react-feather";
import { joinWaitList } from "../../APIs/Repo";
import { CustomError, CustomSuccess } from "../Toast";
import { isNullOrEmpty } from "../../Utils/isNullOrEmpty";
import { isInvalidEmail } from "../../Utils/validations";
import IntlTelInput from "react-intl-tel-input";
import "react-intl-tel-input/dist/main.css";
import SuccessMessageModal from "../SuccessMessageModal";

function Home() {
  const [emailAddress, setEmailAddress] = useState("");
  const [phone, setPhone] = useState("");
  const [code, setCode] = useState("");
  const [isPhoneValid, setIsPhoneValid] = useState(true);
  const [isProcessing, setIsProcessing] = useState(false);
  const [successPopup, setSuccessPopup] = useState(false);
  const [checked, setChecked] = useState(false);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  // useEffect(() => {
  //   setTimeout(() => {
  //     setSuccessPopup(false);
  //   }, 3000);
  // }, [successPopup]);

  function CustomInternationalIcon() {
    return (
      <>
        <Phone fill="black" size={21} />
      </>
    );
  }

  const joinWaitlistApiCall = () => {
    let object = {
      id: 0,
      phone: code.concat(phone),
      email: emailAddress,
    };
    setIsProcessing(true);
    joinWaitList(object)
      .then(({ data }) => {
        setIsProcessing(false);
        if (data.success) {
          setSuccessPopup(true);
          setEmailAddress("");
          setPhone("");
          // CustomSuccess("Hooray! Your request is officially in motion.");
        } else CustomError("Some error occurred, Please try again");
      })
      .catch((err) => {
        setIsProcessing(false);
        console.log("err", err);
        CustomError("Some error occurred, Please try again");
      });
  };

  const handlePhoneNumberChange = (
    isValid,
    value,
    countryData,
    fullNumber,
    ext
  ) => {
    console.log("isValid:", isValid);
    console.log("value:", value);
    console.log("countryData:", countryData);
    console.log("fullNumber:", fullNumber);
    console.log("ext:", ext);
    setIsPhoneValid(isValid);
    setPhone(value);
    setCode(countryData.dialCode);
  };

  return (
    <>
      <div className="main_home" id="Home">
        <Navbar />
        <div className="Find_member">
          <div className="find__member__left__img">
            <img src={couple} />
          </div>
          <div className="Find_member_detail">
            <div className="Find_member_detail_text1">
              {/* Find a Shia partner. */}
              The premier Shia matchmaking app.
              <br />
              Connect with purpose.
            </div>
            {/* <div className="Find_member_detail_text2">
              For those seeking, and their parents.
            </div> */}
            <div className="Home_main_svg">
              <img src={Home_main_svg} alt="" />
            </div>
            <div className="Find_member_box">
              {/* <div className="Find_member_box_text">
                Get Notified When We Launch
              </div> */}
              <div
                className="Email_and_number"
                style={{ marginBottom: ".5em" }}
              >
                <div className="email_svg">
                  <img src={email} alt="" />
                </div>
                <input
                  className="home_email"
                  type="text"
                  placeholder="Email Address"
                  value={emailAddress}
                  onChange={(e) => setEmailAddress(e.currentTarget.value)}
                />
              </div>
              <div className="Email_and_number">
                <div
                  className="flag-select-container"
                  // style={{ paddingBottom: ".2em" }}
                >
                  {/* <CountrySelect
                    labels={en}
                    value={phone}
                    onChange={setPhone}
                  /> */}
                  <IntlTelInput
                    preferredCountries={["ca", "us"]}
                    onPhoneNumberChange={handlePhoneNumberChange}
                    style={{ border: "none", outline: "none", width: "100%" }}
                    defaultCountry="us"
                    // onPhoneNumberBlur={onBlur()}
                    placeholder="Phone Number (optional)"
                  />

                  {/* <PhoneInput
                    // countries={countryOptions}
                    internationalIcon={CustomInternationalIcon}
                    placeholder="Phone Number (optional)"
                    value={phone}
                    onChange={setPhone}
                  /> */}
                </div>
              </div>
              <div
                className="check_box_with_text"
                style={{ marginLeft: "1em" }}
              >
                <div
                  className="check_box_with_text1"
                  style={{ marginTop: "-.15em" }}
                >
                  <label className="custom-checkbox">
                    <input
                      className="checkbox"
                      type="checkbox"
                      onChange={() => setChecked(!checked)}
                      checked={checked}
                    />
                    <span className="checkbox-border">
                      <span className="checkmark">✔</span>
                    </span>
                  </label>
                </div>
                <div className="consent__text" style={{ marginLeft: "-.8em" }}>
                  I consent to receiving promotional emails, newsletters, and/or
                  text messages from ShiaMeets. I understand that I can
                  unsubscribe at any time.
                </div>
              </div>
              <div
                onClick={() => {
                  if (!isProcessing) {
                    if (isNullOrEmpty(emailAddress))
                      CustomError("Please enter email address");
                    else if (isInvalidEmail(emailAddress))
                      CustomError("Please enter valid email address");
                    else if (!isNullOrEmpty(phone) && !isPhoneValid)
                      CustomError("Please enter valid phone number");
                    else if (!checked)
                      CustomError("We need your consent before sign up");
                    else joinWaitlistApiCall();
                  }
                }}
                className="waitlist"
                // style={
                //   !isNullOrEmpty(emailAddress) && checked
                //     ? null
                //     : { background: "gray" }
                // }
              >
                {isProcessing ? "Please Wait..." : "Get Early Access"}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Shia_meet />
      {successPopup && (
        <SuccessMessageModal onClose={() => setSuccessPopup(false)} />
      )}
    </>
  );
}

export default Home;
