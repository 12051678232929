import React, { useEffect } from "react";
import Navbar from "../Navbar/Navbar";
import Email_contact from "../../Assest/email.contact.us.svg";
import Call_contact from "../../Assest/call.contact.svg.svg";
import Address_contact from "../../Assest/Address_contact.svg";
import p_contact from "../../Assest/P_contact.svg";
import i_contact from "../../Assest/i_contact.svg";
import t_contact from "../../Assest/t_contact.svg";
import f_contact from "../../Assest/f_contact.svg";
import "./Contectus.css";
import { useState } from "react";
import { contactUs } from "../../APIs/Repo";
import { CustomError, CustomSuccess } from "../Toast";
import { isNullOrEmpty } from "../../Utils/isNullOrEmpty";
import { isInvalidEmail } from "../../Utils/validations";
function Contectus() {
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [isProcessing, setIsProcessing] = useState(false);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  const contactPostCall = () => {
    let object = {
      id: 0,
      name: name,
      email: email,
      subject: subject,
      message: message,
    };
    setIsProcessing(true);
    contactUs(object)
      .then(({ data }) => {
        setIsProcessing(false);
        if (data.success) {
          setName("");
          setEmail("");
          setSubject("");
          setMessage("");
          CustomSuccess("The deed is done! Your request is off and running.");
        } else CustomError("Some error occurred, Please try again");
      })
      .catch((err) => {
        setIsProcessing(false);
        console.log("err", err);
        CustomError("Some error occurred, Please try again");
      });
  };

  return (
    <div className="contact_us_main_div">
      <div className="contect_us_uper_part">
        <div>
          <Navbar />
        </div>
        <div className="Contect_us_text">Contact us</div>
      </div>
      <div className="contect_us_bottom_part1">
        <div className="contect_us_bottom_part2">
          <div className="contect_us_bottom_part_detail1">
            <div
              className="contect_information_text"
              style={{ textAlign: "center" }}
            >
              Follow Us
            </div>
            {/* <div className="contect_information_text_detail">
              Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do
              eiusmod
            </div>
            <div className="emai_and_call_contact">
              <div>
                <img src={Email_contact} alt="" />
              </div>
              <div className="emai_and_call_contact_detail">
                sample@gmail.com
              </div>
            </div>
            <div className="emai_and_call_contact">
              <div>
                <img src={Call_contact} alt="" />
              </div>
              <div className="emai_and_call_contact_detail">
                +1(361)-260-6000
              </div>
            </div>
            <div className="emai_and_call_contact">
              <div>
                <img src={Address_contact} alt="" />
              </div>
              <div className="emai_and_call_contact_detail">
                6113 Saratoga Blvd, Ste F PMB #193 Christi, TX 78414
              </div>
            </div> */}
            <div
              className="svg_contact"
              style={{
                height: "100%",
                textAlign: "center",
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {/* <div className="p_contact">
                <img src={p_contact} alt="" />
              </div> */}
              <a
                href="https://www.instagram.com/shiameets_app/"
                target="_blank"
              >
                {" "}
                <img src={i_contact} alt="" />
              </a>
              <a href="https://twitter.com/shiameets_app" target="_blank">
                <img src={t_contact} alt="" />
              </a>
              <a href="https://www.facebook.com/shiameetsapp" target="_blank">
                <img src={f_contact} alt="" />
              </a>
            </div>
          </div>
          <div className="contect_us_bottom_part_detail2">
            <div className="get_in_touch">Get in touch!</div>
            <div className="you_name_and_email_contact">
              <div className="you_name_contact">
                <input
                  className="you_name_contact_email"
                  type="text"
                  placeholder="Your Name *"
                  value={name}
                  onChange={(e) => setName(e.currentTarget.value)}
                />
              </div>
              <div>
                <input
                  className="you_name_contact_email"
                  type="text"
                  placeholder="Email Address *"
                  value={email}
                  onChange={(e) => setEmail(e.currentTarget.value)}
                />
              </div>
            </div>
            <div>
              <input
                className="you_name_subject"
                type="text"
                placeholder="Subject"
                value={subject}
                onChange={(e) => setSubject(e.currentTarget.value)}
              />
            </div>
            <div className="App">
              <textarea
                className="Textarea"
                placeholder="Message"
                value={message}
                onChange={(e) => setMessage(e.currentTarget.value)}
              />
            </div>
            <button
              onClick={() => {
                if (!isProcessing) {
                  if (isNullOrEmpty(name)) CustomError("Please enter name");
                  else if (isNullOrEmpty(email))
                    CustomError("Please enter email address");
                  else if (isInvalidEmail(email))
                    CustomError("Please enter valid email address");
                  else if (isNullOrEmpty(subject))
                    CustomError("Please enter subject");
                  else if (isNullOrEmpty(message))
                    CustomError("Please enter message");
                  else contactPostCall();
                }
              }}
              className="submit_button"
            >
              {isProcessing ? "Sending Request..." : "Submit"}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Contectus;
