import React from "react";
import "./Footer.css";
import logo from "../../Assest/logo.svg";
import Svglogo1 from "../../Assest/logosvg1.svg";
import Svglogo2 from "../../Assest/insta.svg";
import Svglogo3 from "../../Assest/twitter.svg";
import Svglogo4 from "../../Assest/fb.svg";
import { Link } from "react-scroll";
import { useNavigate } from "react-router-dom";
function Footer() {
  const navigate = useNavigate();
  return (
    <div className="Footer">
      <div className="Footer_main">
        <Link to="Home" smooth={true} duration={500}>
          <div className="Footer_logo">
            <img src={logo} alt="" />
          </div>
        </Link>

        <div className="footer_svgs">
          {/* <a href="#" target="_blank">
            <img src={Svglogo1} alt="" />
          </a> */}
          <a href="https://www.instagram.com/shiameets_app/" target="_blank">
            <img src={Svglogo2} alt="" />
          </a>
          <a href="https://twitter.com/shiameets_app" target="_blank">
            <img src={Svglogo3} alt="" />
          </a>
          <a href="https://www.facebook.com/shiameetsapp" target="_blank">
            <img src={Svglogo4} alt="" />
          </a>
        </div>
        {/* <div className="footer_text">
          ShiaMeets is a specialized app for Shia Muslims seeking meaningful
          connections and marriage partners. It provides a safe and inclusive
          platform for users to create profiles, search for compatible matches,
          and engage in conversations while respecting Shia values and
          traditions, facilitating the path to lasting relationships.
        </div> */}
      </div>
      <div className="footer__bottom__wrapper">
        <div className="footer__bottom__item"></div>
        <div className="footer__bottom__item">
          <div className="Footer_last_text">
            © All Rights Reserved 2023 ShiaMeets
          </div>
        </div>
        <div className="footer__bottom__item pricavy__text">
          <div
            onClick={() => navigate("/PrivacyPolicy")}
            href="/PrivacyPolicy"
            className="footer_text_privacy "
            style={{ marginLeft: "1em" }}
          >
            Privacy Policy
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
