import axios from "axios";

export const joinWaitList = async (data) => {
  return await axios.post(
    "https://api-shiameets.dsmeglobal.com/sequelize/api/get-notified/post",
    data,
    {
      headers: {
        "Content-Type": "application/json",
      },
      "axios-retry": {
        retries: 5,
      },
    }
  );
};

export const contactUs = async (data) => {
  return await axios.post(
    "https://api-shiameets.dsmeglobal.com/sequelize/api/get-in-touch/post",
    data,
    {
      headers: {
        "Content-Type": "application/json",
      },
      "axios-retry": {
        retries: 5,
      },
    }
  );
};
