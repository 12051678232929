import React, { useState } from "react";
import "./Shia_meet.css";
import image2 from "../../Assest/freeuse.png";
import image3 from "../../Assest/image3.svg";
import image4 from "../../Assest/image4.svg";
import Parental from "../../Assest/prnt.png";
import holistic from "../../Assest/compat.png";
import embrace from "../../Assest/embrace.png";
import greets from "../../Assest/greets.svg";
import Safe from "../../Assest/Safe and Inclusive.svg";
function Shia_meet() {
  const [activeCategory, setActiveCategory] = useState("ShiaMeets"); // Initial active category

  const handleClick = (category) => {
    setActiveCategory(category);
  };
  return (
    <>
      <div
        className="shia_meet_main"
        style={
          activeCategory == "ShiaGreets" ? { backgroundImage: "none" } : null
        }
      >
        {/* <div style={{ position: "relative", zIndex: 10 }}> */}
        <div className="shiameet_catagari" style={{ marginTop: "3em" }}>
          <div
            className={`Shiameet_point_catagari1 ${
              activeCategory === "ShiaMeets" ? "active" : ""
            }`}
            onClick={() => handleClick("ShiaMeets")}
          >
            ShiaMeets
          </div>
          <div
            className={`Shiameet_point_catagari2 ${
              activeCategory === "ShiaGreets" ? "active" : ""
            }`}
            onClick={() => handleClick("ShiaGreets")}
          >
            ShiaGreets
          </div>
        </div>
        {activeCategory == "ShiaGreets" ? (
          <>
            {/* <div
              className="shia_image_block_text_1"
              style={{ display: "flex", margin: "4em 0em" }}
            >
              Coming{" "}
              <span
                className="shia_image_block_text_1_color"
                style={{ marginLeft: ".2em" }}
              >
                {" "}
                Soon!
              </span>
            </div>
            <div
              className="shia_image_block_text_2"
              style={{ visibility: "hidden" }}
            >
              Connect with individuals who share your devotion to Islam through
              the teachings of the Ahl al-Bayt. ShiaMeets celebrates the unique
              traditions and the diverse values of Shia Islam, ensuring a deeper
              connection based on your shared beliefs.
            </div> */}
            <div className="shia_image_block__greet">
              <div
                className="shia_image_block_image"
                style={{ display: "flex", justifyContent: "flex-end" }}
              >
                <img src={greets} alt="" />
              </div>
              <div className="shia_image_block_text">
                <div className="shia_image_block_text">
                  <div
                    className="shia_image_block_text_1"
                    style={{ display: "flex", margin: "4em 0em" }}
                  >
                    Coming{" "}
                    <span
                      className="shia_image_block_text_1_color"
                      style={{ marginLeft: ".2em" }}
                    >
                      {" "}
                      Soon!
                    </span>
                  </div>
                  <div
                    className="shia_image_block_text_2"
                    style={{ visibility: "hidden" }}
                  >
                    Browse curated profiles, use basic filters, and chat with
                    matches for free. Our app is free to use, allowing you to
                    create your profile and explore potential connections with
                    ease. Pay only to benefit from premium features.
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="shia_image_block">
              <div className="shia_image_block_image">
                <img src={image2} alt="" />
              </div>
              <div className="shia_image_block_text">
                <div className="shia_image_block_text">
                  <div className="shia_image_block_text_1">
                    Free to{" "}
                    <span className="shia_image_block_text_1_color">Use</span>
                  </div>
                  {/* <div className="shia_image_block_text_1">Free to Use</div> */}
                  <div className="shia_image_block_text_2">
                    Browse curated profiles, use basic filters, and chat with
                    matches for free. Our app is free to use, allowing you to
                    create your profile and explore potential connections with
                    ease. Pay only to benefit from premium features.
                  </div>
                </div>
              </div>
            </div>
            <div className="shia_image_block shia_image_block_reverse">
              <div className="shia_image_block_text">
                <>
                  <div className="shia_image_block_text_1">
                    Embrace{" "}
                    <span className="shia_image_block_text_1_color">
                      Shia Values
                    </span>
                  </div>
                  <div className="shia_image_block_text_2">
                    Connect with individuals who share your devotion to Islam
                    through the teachings of the Ahl al-Bayt. ShiaMeets
                    celebrates the unique traditions and the diverse values of
                    Shia Islam, ensuring a deeper connection based on your
                    shared beliefs.
                  </div>
                </>
              </div>
              <div className="shia_image_block_image">
                <img src={embrace} alt="" />
              </div>
            </div>
            <div className="shia_image_block">
              <div className="shia_image_block_image">
                <img src={holistic} alt="" className="" />
              </div>
              <div className="shia_image_block_text">
                <div className="shia_image_block_text_1">
                  Holistic{" "}
                  <span className="shia_image_block_text_1_color">
                    Compatibility
                  </span>
                </div>
                <div className="shia_image_block_text_2">
                  Our advanced compatibility assessment delves into not only
                  your interests and goals but also your religious alignment and
                  social and cultural compatibility. Find a partner who
                  resonates with your identity on a profound level.
                </div>
              </div>
            </div>
            <div className="shia_image_block shia_image_block_reverse Parent_box">
              <div className="shia_image_block_text">
                <div className="shia_image_block_text_1 ">
                  {/* prnt_rep */}
                  Parental{" "}
                  <span className="shia_image_block_text_1_color">
                    Representation
                  </span>
                </div>
                <div className="shia_image_block_text_2">
                  Parents can create identifiable profiles for their children to
                  browse available matches according to their customized
                  preferences. Users have the option to make their profiles
                  available to both parents and spousal candidates, or either of
                  the two.
                </div>
              </div>
              <div className="shia_image_block_image prnt_img">
                <img src={Parental} alt="" />
              </div>
            </div>
            <div className="shia_image_block">
              <div className="shia_image_block_image">
                <img src={Safe} alt="" />
              </div>
              <div className="shia_image_block_text">
                <div className="shia_image_block_text_1">
                  Safe and{" "}
                  <span className="shia_image_block_text_1_color">
                    Inclusive
                  </span>
                </div>
                <div className="shia_image_block_text_2">
                  With an in-built anti-screenshot feature and a selfie
                  verification method to validate profiles, feel secure in an
                  environment that prioritizes your privacy and safety.{" "}
                </div>
              </div>
            </div>
          </>
        )}
        {/* </div> */}
      </div>
    </>
  );
}

export default Shia_meet;
