import React from "react";
import { X } from "react-feather";
import tick from "../Assest/tick.svg";

export default function SuccessMessageModal({ onClose }) {
  return (
    <div className="customer__detail__popup">
      <div className="customer__detail__popup__overlay">
        <div className="customer__detail__popup__overlay__card">
          <button
            onClick={() => onClose(false)}
            className="customer__detail__popup__overlay__card__close"
          >
            <X size={20} color="currentColor" />
          </button>
          <div className="customer__detail__popup__overlay__card__heading">
            You have successfully joined the waitlist.
          </div>
          <img src={tick} style={{ width: "30%", margin: "5em 0em" }} />
          <div className="bottom__text">
            We will notify you via email and/or phone number when the beta
            version of the app is <span>ready for download!</span>
          </div>
        </div>
      </div>
    </div>
  );
}
