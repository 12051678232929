import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "../src/Components/Home/Home";
import Footer from "../src/Components/Footer/Footer";
import "@fontsource/poppins";
import "@fontsource/plus-jakarta-sans";
import Contectus from "./Components/Contect_us/Contectus";
import Blog from "./Components/Blog/Blog";
import { ToastContainer } from "react-toastify";
import PrivacyPolicy from "./Components/Privacypolicy/PrivacyPolicy";

// import AOS from "aos";
// import "aos/dist/aos.css";

// AOS.init();
function App() {
  return (
    <>
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/Contact_us" element={<Contectus />}></Route>
          <Route path="/Blog" element={<Blog />}></Route>
          <Route path="/PrivacyPolicy" element={<PrivacyPolicy />}></Route>
        </Routes>
        <Footer />
      </Router>
      <ToastContainer style={{ fontSize: "14px" }} />
    </>
  );
}

export default App;
