import React from "react";
import Navbar from "../Navbar/Navbar";
import "./PrivacyPolicy.css";
import { useEffect } from "react";
function PrivacyPolicy() {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);
  return (
    <div>
      <div className="policy_us_uper_part">
        <div>
          <Navbar />
        </div>
        <div className="policy_us_text">Privacy Policy</div>
      </div>
      {/* <div style={{ width: "80%", margin: "0 auto" }}>hi</div> */}
      <div className="policy__wrapper">
        <div className="list__heading">1. Introduction</div>
        <div className="list__desc">
          Welcome to ShiaMeets (hereinafter referred to as "the App"), designed
          to assist primarily Shia Muslims in finding compatible partners for
          marriage and expanding their social and professional networks. We
          prioritize your privacy and data security. This Privacy Policy
          explains how we collect, use, disclose, and safeguard your personal
          information when you use our App, including details related to our
          premium subscription plan.
        </div>
        <div className="list__desc">
          <strong>a</strong>. By using the App, you consent to the practices
          described in this Privacy Policy. If you do not agree with this
          Privacy Policy, please do not use our App.
        </div>
        <div className="list__desc">
          <strong>b</strong>. Registration Information: When you create an
          account on the App, we collect specific personal information,
          including your name, email address, gender, date of birth, and a
          profile picture. Optionally, you may provide additional information
          about your ethnicity, interests, and preferences. A selfie photo will
          be collected if you choose to verify your profile.
        </div>
        <div className="list__heading">2. Information We Collect</div>
        <div className="list__desc">
          <strong>b</strong>. Payment Information: If you decide to subscribe to
          our premium plan, we will collect payment information, such as credit
          card details or other payment methods, to process your payments
          securely.
        </div>
        <div className="list__desc">
          <strong>c</strong>. Profile Information: You have the option to
          provide further details in your profile, including educational
          background, occupation, and religious preferences.
        </div>
        <div className="list__desc">
          <strong>d</strong>. Communication Data: We may collect information
          related to your interactions with other users, such as messages,
          photos, voice notes, audio, and video calls exchanged through the App.
        </div>
        <div className="list__desc">
          <strong>e</strong>. Device Information: We automatically gather
          certain information about your device, including device type,
          operating system, and unique device identifiers.
        </div>
        <div className="list__desc">
          <strong>f</strong>. Usage Data: We may collect data about how you use
          the App, including which features you access, the content you view,
          and your interactions with other users.
        </div>
        <div className="list__heading">3. How We Use Your Information</div>
        <div className="list__desc">
          <strong>a. Matchmaking:</strong> We use the information you provide to
          match you with potential partners based on compatibility factors,
          including religious preferences and other criteria you specify.
        </div>
        <div className="list__desc">
          <strong>b. Networking:</strong> We facilitate networking opportunities
          by connecting you with other users who share similar interests or
          professional backgrounds, helping you expand your social and
          professional circles. securely.
        </div>
        <div className="list__desc">
          <strong>c. Communication:</strong> We enable communication between
          users, including text, voice notes, audio, and video calls, and
          provide features for messaging and interaction within the App.
        </div>
        <div className="list__desc">
          <strong>d. Customer Support:</strong> Your information helps us
          provide efficient customer support, respond to your inquiries, and
          resolve any issues you may encounter.
        </div>
        <div className="list__desc">
          <strong>e. Service Improvements:</strong> We may use your data to
          analyze and enhance the App's functionality, user experience, and
          performance.
        </div>
        <div className="list__desc">
          <strong>f. Marketing and Promotions:</strong> With your consent, we
          may use your email address to send you promotional and informational
          materials about the App, including special offers and updates.
        </div>
        <div className="list__heading">4. How We Protect Your Information</div>
        <div className="list__desc">
          We implement robust security measures to safeguard your personal
          information from unauthorized access, disclosure, alteration, and
          destruction. These measures include encryption, secure data storage,
          and regular security assessments.
        </div>
        <div className="list__heading">5. Data Sharing</div>
        <div className="list__desc">
          <strong>a. Third Parties:</strong> We may share your information with
          third-party service providers, such as payment processors and hosting
          providers, to facilitate App functionality and payment processing.
          These third parties are required to adhere to strict data protection
          standards.
        </div>
        <div className="list__desc">
          <strong>b. Legal Requirements:</strong> We may disclose your
          information if required by law or in response to a court order. We
          will not disclose your information in response to a government request
          unless absolutely required by law.
        </div>
        <div className="list__heading">6. Your Choices</div>
        <div className="list__desc">
          <strong>a. Access and Correction:</strong> You can review and update
          your profile information at any time within the App.
        </div>
        <div className="list__desc">
          <strong>b. Marketing Preferences:</strong> You can opt out of
          receiving promotional emails by following the instructions in the
          emails.
        </div>
        <div className="list__heading">7. Children's Privacy</div>
        <div className="list__desc">
          The App is intended for use by adults and is not directed toward
          individuals under the age of 18. We do not knowingly collect personal
          information from minors.
        </div>
        <div className="list__heading">8. Changes to this Privacy Policy</div>
        <div className="list__desc">
          We may update this Privacy Policy periodically. Significant changes
          will be notified, and the revised policy will be posted on the App. We
          recommend reviewing the Privacy Policy regularly.
        </div>
        <div className="list__heading">9. Contact Us</div>
        <div className="list__desc">
          If you have any questions or concerns about this Privacy Policy or our
          data practices, please contact us through www.shiameets.com.
        </div>
        <div className="list__desc" style={{ marginTop: "2em" }}>
          Thank you for using ShiaMeets. We are committed to helping you find
          compatible partners and expand your social and professional networks
          while safeguarding your privacy.
        </div>
      </div>
    </div>
  );
}

export default PrivacyPolicy;
